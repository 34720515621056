<template>
  <div id="loginPage" class="min-h-screen bg-white flex">
    <!-- Left Side -->
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-24 xl:px-24 leftLoginDiv">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div class="w-full text-center border-red">
          <img class="h-12" src="@/assets/images/logo-full.png" alt="Growlytics Login" />
        </div>

        <div class="mt-6">
          <!-- Error Message -->
          <div v-if="errorMsg" class="errorLabel bg-red-600 text-white p-2 rounded">
            {{ errorMsg }}
          </div>

          <!-- Form -->
          <div class="mt-6">
            <el-form ref="loginForm" label-position="top" label-width="100px" :model="loginForm" :rules="formRules">
              <el-form-item label="Email address" prop="email">
                <el-input id="email" name="email" type="email" autocomplete="email" v-model="loginForm.email" required></el-input>
              </el-form-item>
              <el-form-item label="Password" prop="password">
                <el-input id="passwordInput" type="password" v-model="loginForm.password" required></el-input>
              </el-form-item>
            </el-form>
          </div>

          <button @click="onLoginSubmit" class="w-full mt-8 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">Sign in</button>

          <div class="mt-3">
            <div class="text-sm text-center">
              <a href="/forgotPass" class="font-medium text-primary-600 hover:text-primary-500"> Forgot your password? </a>
            </div>
            <div class="text-sm text-center mt-6">Don't have account? <a href="/register" class="font-medium text-primary-600 hover:text-primary-500"> Register </a></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Side -->
    <div class="hidden h-screen lg:block flex-1 overflow-hidden px-20 bg-gray-800">
      <div class="rightSide">
        <el-carousel :interval="10000" class="px-20 w-full rounded overflow-hidden" indicator-position="outside">
          <el-carousel-item v-for="(item, index) in changeLog" :key="index">
            <div>
              <img :src="item.image" class="w-full" />
              <div class="px-4 py-3 w-full">
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-secondary-500 text-white"> {{ item.tag }} </span>
                <div class="mt-2 text-lg font-bold text-gray-800">{{ item.title }}</div>
                <div class="mt-1 text-sm text-gray-500">{{ item.description }}</div>
                <ul class="mt-3 text-sm text-gray-500">
                  <li v-for="(feature, index1) in item.features" :key="index1" class="ml-2 mb-1" style="list-style-type: none">
                    <i class="el-icon-check font-bold"></i>&nbsp;
                    {{ feature }}
                  </li>
                </ul>

                <a target="_blank" :href="item.link" class="inline-flex items-center mt-4 px-3 py-2 text-sm font-medium rounded-md text-secondary-600 border-2 border-secondary-600 hover:bg-secondary-600 hover:text-white">{{ item.linkText }} &nbsp; <i class="el-icon-top-right font-bold"></i></a>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import LoginComponent from './loginComponent';
export default LoginComponent;
</script>

<style lang="scss" src="./login.scss"></style>
