import authService from '../../../services/auth';

export default {
  name: 'login',
  data() {
    return {
      errorMsg: '',
      loginProcessing: false,

      loginForm: null,
      formRules: null,

      changeLog: [
        {
          image: 'https://cloud.headwayapp.co/changelogs_images/images/big/000/079/800-ca2e307374e6244535172f3cb2a6772de2a4410d.png',
          title: 'Scheduled Campaign Reports',
          tag: 'NEW',
          description: 'Schedule daily, weekly and monthy campaign reports on email.',
          features: ['Receive emails daily, weekly and monthly', 'Set the users who will receive emails', 'Specify campaigns and journeys to receive reports'],
          linkText: 'Learn More',
          link: 'http://help.growlytics.in/en/articles/5795013-scheduling-campaign-reports'
        },
        {
          image: 'https://cloud.headwayapp.co/changelogs_images/images/big/000/077/317-0a39a93197540e66451dd127fe63066594b7a37a.png',
          title: 'New Journey Trigger Option: Enter/Leave Segment',
          tag: 'NEW',
          description: 'Now you can send automated messages to customers when they enter or leave segments on daily basis.',
          features: ['Add customer to journey when enters segment', 'Add customer to journey when leaves segment'],
          linkText: 'Learn More',
          link: 'http://help.growlytics.in/en/articles/5680152-journey-triggers-how-it-works'
        },
        {
          image: 'https://cloud.headwayapp.co/changelogs_images/images/big/000/075/768-9d0f2dd3b1258dbb723e522a5256164f344e8490.png',
          title: 'Frequency Capping Feature Is Now Available',
          tag: 'NEW',
          description: 'Use frequency capping to specify a limit on the maximum number of campaign messages a customer can receive in given time period.',
          features: ['Set max number of emails/smses customers can receive.', 'Set limits on daily, weekly and monthly basis.'],
          linkText: 'Learn how to use frequency capping',
          link: 'http://help.growlytics.in/en/articles/5605313-setting-up-frequency-capping'
        },
        {
          image: 'https://cloud.headwayapp.co/changelogs_images/images/big/000/075/631-bbec02c6f7b53814126b2abd6e28044d9a94200e.png',
          title: 'Add custom html in Email Drag-Drop Editor',
          tag: 'NEW',
          description: 'Now you can add your custom html inside email drag drop editor. This will help you customize some part of drag-drop editor your way.',
          features: ['Put your custom designed HTMLs.', 'Advanced snippets like count-down timer are also supported..'],
          linkText: 'Learn how to use custom-html in email',
          link: 'http://help.growlytics.in/en/articles/5602495-using-custom-html-in-email-drag-drop-editor'
        },
        {
          image: 'https://cloud.headwayapp.co/changelogs_images/images/big/000/075/284-fff0705c1028ec28381a1f2620fe661aaf8fd588.png',
          title: 'Wait for morning or evening timeslots.',
          tag: 'NEW',
          description: 'Use time-slots in journey to send campaign messages at the right time when customers are most active.',
          features: ['Send automated messages at morning or evening time-slots.', 'Mention multiple time-slots to wait for.'],
          linkText: 'Learn how to use time-slots in journey',
          link: 'http://help.growlytics.in/en/articles/5583396-using-time-slots-in-journey'
        },
        {
          image: 'https://cloud.headwayapp.co/changelogs_images/images/big/000/072/319-fb3b9a70b28c442f6b19f7f44b7438ea81a804e3.png',
          title: 'Growlytics + Zoko Integration',
          tag: 'NEW',
          description: 'We’re happy to announce that Growlytics and Zoko have partnered to help you provide seamless engagement on WhatsApp.',
          features: ['Send bulk and automated WhatsApp messages from Growlytics.', 'Track conversions and revenue for each message being sent.'],
          linkText: 'Learn how to integrate zoko account',
          link: 'http://help.growlytics.in/en/articles/5457027-zoko-integration'
        },
        {
          image: 'https://cloud.headwayapp.co/changelogs_images/images/big/000/072/320-37ecba684a439e86d2b715b7cae3fc430220abee.png',
          title: 'Convert more customers with unique coupons.',
          tag: 'NEW',
          description: 'Generate & send one-time coupons to your customers based on their behaviour. Coupons are customer specific and can not be miss-used.',
          features: ['Mention coupons inside Email, SMS and WhatsApp.', 'Percentage or Fixed amount or free shipping discount.'],
          linkText: 'Learn how to send coupons',
          link: 'http://help.growlytics.in/en/articles/5400692-how-to-generate-and-send-coupon-codes-in-growlytics'
        }
      ]
    };
  },
  computed: {},
  methods: {
    // Normal Login with email password.
    async onLoginSubmit() {
      try {
        this.errorMsg = '';

        // Validate Form
        let isValidData = await this.validateForm(this.$refs.loginForm);
        if (!isValidData) return;

        this.loginProcessing = true;

        let result = await authService.login(this.loginForm);

        if (result.data.status == 200) {
          this.handleNewSession(result.data);
        } else if (result.data.status == 401) {
          this.errorMsg = result.data.message;
        } else if (result.data.status == 403) {
          this.errorMsg = result.data.message;
        }

        this.loginProcessing = false;
      } catch (err) {
        this.loginProcessing = false;
        this.errorMsg = `Something's wrong. Please contact our support team.`;
        console.error(err);
      }
    }
  },

  created() {
    // Read form and rules from mixin
    this.loginForm = this.getLoginForm();
    this.formRules = this.getLoginFormRules();

    if (this.$route.query['shopify-session-token']) {
      this.loginProcessing = true;
      setTimeout(() => {
        this.handleShopifyLogin();
      }, 1000);
    } else if (this.$route.query.message && this.$route.query.message != '') {
      this.errorToast(this.$route.query.message);
    } else if (this.$route.query.successMsg && this.$route.query.successMsg != '') {
      this.successToast(this.$route.query.successMsg, 6000);
    }
  }
};
